@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.flex {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contatain {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // background: #fff;
}
.content {
  padding: 16px 16px;

  position: relative;
  flex: 1;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(38, 28, 87, 0.11);

  border-radius: 4px;
}
.tab {
  display: flex;
  &-item {
    width: 96px;
    height: 24px;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    margin-right: 35px;
  }
  &-item-active {
    color: #fff;
    background: #7f76bd;
  }
}
.creatNewBtn {
  background-image: url("../../assets/newscr_btn_n@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  width: 212px;
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  cursor: pointer;
}

.item {
  color: rgba(51, 51, 51, 1);
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .item-index {
    margin-left: 10px;
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid rgba(197, 192, 224, 1);
    color: rgba(197, 192, 224, 1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }
  .item-title {
    margin-left: 10px;
    width: 25%;
  }
  .item-message {
    margin-left: 10px;
    width: 30%;
  }
  .item-other {
    width: 45%;
    display: flex;
    justify-content: space-around;
  }
  .item-check-true {
    height: 20px;
    width: 80px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #c5c0e0;
  }
  .item-check-false {
    width: 64px;
    height: 24px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }
  .item-date {
    color: rgba(153, 153, 153, 1);
    margin-left: 10px;
    // width: 30%;
    text-align: right;
  }
  .item-sale {
    width: 96px;
    height: 24px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #7f76bd;
    line-height: 24px;
    text-align: right;
  }
  .item-state {
    width: 54px;
    display: flex;
    justify-content: flex-end;
    .state {
      font-size: 14px;
      width: 54px;
      height: 20px;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
  }
}
.resetBtn {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c5c0e0;
  line-height: 20px;
  width: 80px;
  text-align: center;
  height: 20px;
  font-size: 14px;
  color: #333;
}
.delBtn {
  width: 80px;
  height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f394ac;
  line-height: 20px;
  color: #f394ac;
  text-align: center;
}
.pagination {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.title {
  width: 96px;
  height: 24px;
  background: #eceafa;
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
}
